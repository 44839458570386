import { useEffect, useState } from 'react'
import { m, AnimatePresence } from 'framer-motion'
import styled from '@emotion/styled'

import ColumnTrack from '@segment/matcha/components/ColumnTrack'

import { ChevronRightIcon } from '@segment/matcha/components/Icons/Common'

import ButtonText from '@segment/matcha/components/ButtonText'

import CardBase, * as Card from '@segment/matcha/recipes/Card'
import CopyTextBase, * as CopyText from '@segment/matcha/recipes/CopyText'
import SectionBase, * as Section from '@segment/matcha/recipes/Section'

import getAbsoluteUrl from '@segment/utils/getAbsoluteUrl'

import { usePageConfig } from '@segment/contexts/page'

import illoUseCaseActivateDefault from './assets/IlloUseCaseActivateCustomerDataDefault.png'
import illoUseCaseActivateHover from './assets/IlloUseCaseActivateCustomerDataHover.png'
import illoUseCaseBoostDefault from './assets/IlloUseCaseBoostCrossSellDefault.png'
import illoUseCaseBoostHover from './assets/IlloUseCaseBoostCrossSellHover.png'
import illoUseCaseEnrichDefault from './assets/IlloUseCaseEnrichCustomerProfilesDefault.png'
import illoUseCaseEnrichHover from './assets/IlloUseCaseEnrichCustomerProfilesHover.png'
import illoUseCaseOptimizeDefault from './assets/IlloUseCaseOptimizeAdSpendDefault.png'
import illoUseCaseOptimizeHover from './assets/IlloUseCaseOptimizeAdSpendHover.png'

const copy = {
  heading: `with good data`,
}

const headings = [
  `AI`,
  `Personalization`,
  `Compliance`,
  `Advertising`,
  `Scalability`,
  `Segmentation`,
  `ROI`,
  `Everything`,
]

const cards = [
  {
    title: `Enrich customer profiles`,
    description: `Build and automatically enrich identity-resolved profiles with every new interaction to better predict and provide relevant customer experiences in real time.`,
    link: `/use-cases/enrich-customer-profiles/`,
    buttonText: `Unify & enrich your data`,
    imageDefault: illoUseCaseEnrichDefault,
    imageHover: illoUseCaseEnrichHover,
  },
  {
    title: `Activate customer data`,
    description: `Our 450+ pre-built connectors make it fast and easy to get all the data you need into unified customer profiles—and activate those profiles to power the tools you use.`,
    link: `/use-cases/activate-customer-data/`,
    buttonText: `Activate your data`,
    imageDefault: illoUseCaseActivateDefault,
    imageHover: illoUseCaseActivateHover,
  },
  {
    title: `Optimize ad spend`,
    description: `Make it easy for marketers to activate first-party data and use AI to build more predictive, precise, and personalized ads that drive conversions and lower customer acquisition costs.`,
    link: `/use-cases/optimize-ad-spend/`,
    buttonText: `Increase your RoAS`,
    imageDefault: illoUseCaseOptimizeDefault,
    imageHover: illoUseCaseOptimizeHover,
  },
  {
    title: `Boost cross-sell and upsell`,
    description: `Use AI to anticipate customer needs and recommend the right item to the right customers at the best time for conversion to drive repeat sales.`,
    link: `/use-cases/boost-cross-sell-upsell/`,
    buttonText: `Increase Customer Value`,
    imageDefault: illoUseCaseBoostDefault,
    imageHover: illoUseCaseBoostHover,
  },
]

const DataArchitecture = () => {
  const pageConfig = usePageConfig()
  const [index, setIndex] = useState(0)

  useEffect(() => {
    const id = setInterval(() => {
      setIndex((state) => {
        if (state >= headings.length - 1) return 0
        return state + 1
      })
    }, 2000)

    return () => clearInterval(id)
  }, [])

  return (
    <StyledSectionBase variant="centered">
      <StyledSectionCopy>
        <CopyTextBase centered={true}>
          <StyledCopyTextHeading tag="h2" variant="homeH2">
            <AnimatePresence initial={false}>
              <RotatingHeadingWrapper
                key={headings[index]}
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: -20, opacity: 0 }}
                transition={{ ease: `easeInOut` }}
              >
                <RotatingHeading>{headings[index]} is better</RotatingHeading>
              </RotatingHeadingWrapper>
            </AnimatePresence>
            <br />
            <StaticHeading>{copy.heading}</StaticHeading>
          </StyledCopyTextHeading>
        </CopyTextBase>
      </StyledSectionCopy>
      <Section.Contents>
        <ColumnTrack columns={1} overrides={{ md: 2, lg: 4 }}>
          {cards.map((card) => (
            <StyledCardBase appearance="twilioLight" href={getAbsoluteUrl(card.link, pageConfig)} key={`${card.title}`}>
              <CardImageBox>
                <CardImage alt="" src={card.imageDefault} />
                <OverlayingCardImage alt="" src={card.imageHover} />
              </CardImageBox>
              <Card.Body>
                <Card.Copy>
                  <StyledCardHeadline variant="h3" tag="h3">
                    {card.title}
                  </StyledCardHeadline>
                  <Card.Description>{card.description}</Card.Description>
                </Card.Copy>
                <Card.Action>
                  <ButtonText tag="span" size="medium" iconEnd={ChevronRightIcon}>
                    {card.buttonText}
                  </ButtonText>
                </Card.Action>
              </Card.Body>
            </StyledCardBase>
          ))}
        </ColumnTrack>
      </Section.Contents>
    </StyledSectionBase>
  )
}

const StyledSectionBase = styled(SectionBase)`
  ${Section.Wrapper} {
    row-gap: 64px;
  }
`

const StyledSectionCopy = styled(Section.Copy)`
  width: 100%;
`

const StyledCardHeadline = styled(Card.Headline)`
  font-weight: 700;
`

const StyledCardBase = styled(CardBase)`
  // Needs to be styled in order for the hover effect to work
`

const CardImageBox = styled.div`
  position: relative;
`

const CardImage = styled.img`
  width: 100%;

  z-index: 1;
  ${StyledCardBase}:hover & {
    z-index: 0;
  }
`

const OverlayingCardImage = styled.img`
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;

  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  ${StyledCardBase}:hover & {
    opacity: 1;
    z-index: 1;
  }
`

const StyledCopyTextHeading = styled(CopyText.Heading)`
  position: relative;
  width: 100%;
`

const StaticHeading = styled.span`
  font-weight: 400;
  line-height: 1.4;

  ${({ theme }) => theme.breakpoints.down(`sm`)} {
    font-size: 28px;
  }
`

const RotatingHeadingWrapper = styled(m.span)`
  position: absolute;
  left: 0;
  width: 100%;
`

const RotatingHeading = styled.span`
  position: relative;

  &::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    bottom: -5px;
    height: 3px;
    width: 100%;
    border-bottom: 3px dotted ${({ theme }) => theme.palette.twilio.green[60]};
  }

  ${({ theme }) => theme.breakpoints.down(`sm`)} {
    font-size: 28px;
  }
`

export default DataArchitecture
