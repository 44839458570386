import React from 'react'
import styled from '@emotion/styled'

import Button from '@segment/matcha/components/Button'
import ButtonText from '@segment/matcha/components/ButtonText'
import ColumnTrack from '@segment/matcha/components/ColumnTrack'
import Image from '@segment/matcha/components/Image'
import { ChevronRightIcon } from '@segment/matcha/components/Icons/Common'

import FeatureBoxBase, * as FeatureBox from '@segment/matcha/components/FeatureBox'

import SectionBase, * as Section from '@segment/matcha/recipes/Section'
import CopyTextBase, * as CopyText from '@segment/matcha/recipes/CopyText'

import getAbsoluteUrl from '@segment/utils/getAbsoluteUrl'

import { usePageConfig } from '@segment/contexts/page'

import WarehouseDataPng2x from './assets/WarehouseData@2x.png'
import WarehouseDataWebp from './assets/WarehouseData.webp'
import WarehouseDataWebp2x from './assets/WarehouseData@2x.webp'

const copy = {
  heading: `Activate warehouse data with a CDP that’s powerful yet flexible`,
  description: `Twilio Segment makes it easy for data teams to prepare, enrich, and activate existing data in the warehouse, so marketers can move fast with personalized communication.`,
  button: {
    text: `Explore Reverse ETL`,
    href: `/product/reverse-etl/`,
  },
  buttonText: {
    text: `View docs`,
    href: `https://segment.com/docs/`,
  },
}

const stats = [
  {
    heading: `#1 CDP`,
    description: `in 2022 market share per IDC*`,
  },
  {
    heading: `12,696`,
    description: `unique events tracked**`,
  },
  {
    heading: `12.1 Trillion`,
    description: `API calls processed in 2023**`,
  },
]

const data = [
  {
    heading: `Keep your data where it is`,
    description: `With a zero copy architecture that treats the warehouse as a source of truth, you can make event streams, product information, and entity data open to any team and application.`,
  },
  {
    heading: `Get extensibility without complexity`,
    description: `Build, deploy, and scale on a platform that easily integrates with your tech stack so you can quickly adapt to changing customer needs while maintaining data privacy.`,
  },
  {
    heading: `Enhance every experience with warehouse data`,
    description: `Enrich and unlock customer profiles across your organization with real-time data and predictive AI to make every customer interaction more impactful.`,
  },
]

const ActivateWarehouseData = () => {
  const pageConfig = usePageConfig()

  return (
    <StyledSection variant="centered">
      <Section.Copy>
        <CopyTextBase centered={true}>
          <CopyText.Heading tag="h2" variant="homeH2">
            {copy.heading}
          </CopyText.Heading>
          <CopyText.Description tag="p" variant="homePExtraLarge">
            {copy.description}
          </CopyText.Description>
        </CopyTextBase>
      </Section.Copy>

      <Section.Contents>
        <ColumnTrack columns={1} overrides={{ lg: 3 }}>
          {stats.map((item) => (
            <FeatureBoxBase key={item.heading}>
              <FeatureBox.Header>
                <FeatureBox.Title variant="homeH2">{item.heading}</FeatureBox.Title>
                <FeatureBox.Title variant="pMedium">{item.description}</FeatureBox.Title>
              </FeatureBox.Header>
            </FeatureBoxBase>
          ))}
        </ColumnTrack>

        <Content>
          <picture>
            <source type="image/webp" srcSet={`${WarehouseDataWebp}, ${WarehouseDataWebp2x} 2x`} />
            <StyledImage src={WarehouseDataPng2x} alt="Warehouse Data" width="733" height="733" loading="lazy" />
          </picture>

          <Copy>
            {data.map((item) => (
              <FeatureBoxBase alignment="left" key={item.heading}>
                <FeatureBox.Body>
                  <FeatureBox.Title variant="homeH5" tag="h3">
                    {item.heading}
                  </FeatureBox.Title>
                  <FeatureBox.Title variant="pMedium" tag="p">
                    {item.description}
                  </FeatureBox.Title>
                </FeatureBox.Body>
              </FeatureBoxBase>
            ))}
          </Copy>
        </Content>

        <ButtonsWrapper>
          <Button
            key={copy.button.text}
            size="large"
            color="primary"
            variant="filled"
            href={getAbsoluteUrl(copy.button.href, pageConfig)}
          >
            {copy.button.text}
          </Button>
          <ButtonText
            key={copy.buttonText.text}
            href={getAbsoluteUrl(copy.buttonText.href, pageConfig)}
            iconEnd={ChevronRightIcon}
          >
            {copy.buttonText.text}
          </ButtonText>
        </ButtonsWrapper>
      </Section.Contents>
    </StyledSection>
  )
}

const StyledSection = styled(SectionBase)`
  ${Section.Wrapper} {
    ${({ theme }) => theme.breakpoints.down(`md`)} {
      gap: 40px;
    }
  }
`

const Content = styled.div`
  margin: 60px 0;
  display: grid;
  gap: 80px;
  grid-auto-flow: column;
  align-items: center;

  ${({ theme }) => theme.breakpoints.down(`md`)} {
    grid-auto-flow: row;
    gap: 40px;
  }
`

const StyledImage = styled(Image)`
  width: 100%;
  max-width: 490px;
  filter: drop-shadow(${({ theme }) => theme.shadows[2]});
`

const Copy = styled.div`
  display: flex;
  flex-direction: column;
  gap: 60px;

  ${({ theme }) => theme.breakpoints.down(`lg`)} {
    gap: 40px;
  }
`

const ButtonsWrapper = styled.div`
  display: flex;
  gap: 24px;
  justify-content: center;
  align-items: center;
`

export default ActivateWarehouseData
