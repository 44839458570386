/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
var ProductIcon = createIconsComponent(_jsx("svg", {
  viewBox: "0 0 25 25",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M12.761 2H2v20.49h10.761V2ZM2 0a2 2 0 0 0-2 2v20.49a2 2 0 0 0 2 2h10.761a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2ZM18.761 2.472a1 1 0 0 1 1 1v17.543a1 1 0 1 1-2 0V3.472a1 1 0 0 1 1-1ZM23.761 5.495a1 1 0 0 1 1 1v11.5a1 1 0 1 1-2 0v-11.5a1 1 0 0 1 1-1Z",
    fill: "none"
  })
}), 'ProductIcon', 'Twilio');
export default ProductIcon;