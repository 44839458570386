import { useState, type FC, useEffect } from 'react'
import styled from '@emotion/styled'
import type { AnimationItem } from 'lottie-web'

import { AppearanceProvider, useAppearance } from '@segment/matcha/foundations/AppearanceContext'

import { ChevronRightIcon } from '@segment/matcha/components/Icons/Common'

import * as CopyText from '@segment/matcha/recipes/CopyText'
import HeroRecipeBase, * as HeroRecipe from '@segment/matcha/recipes/Hero'
import { SignupButtonText } from '@segment/matcha/recipes/SignupButton'

import DemoForm from '@segment/templates/Forms/DemoForm'

import type { AppearanceProps } from '@segment/matcha/types'

import useIsInView from '@segment/utils/useInView'
import useLottie from '@segment/utils/useLottie/useLottie'

import heroPng2x from './assets/Hero@2x.png'
import heroWebp from './assets/Hero.webp'
import heroWebp2x from './assets/Hero@2x.webp'

import jsonUrl from './assets/illo-hero-leading-customer-ai-powered_F2.json.data'

const copy = {
  heading: `The leading customer data platform, powered by CustomerAI`,
  description: `Twilio Segment brings together clean, consented customer data for real-time insights so you can know each individual like they are your only customer.`,
}

const Hero: FC<AppearanceProps> = ({ appearance: externalAppearance }) => {
  const appearance = useAppearance(externalAppearance)

  const [animationData, setAnimationData] = useState<Promise<Response>>()
  useEffect(() => {
    setAnimationData(fetch(jsonUrl))
  }, [])
  const [animation, containerRef] = useLottie<AnimationItem, HTMLDivElement>({
    options: {
      autoplay: true,
      animationData,
      loop: true,
    },
  })
  const isInView = useIsInView(containerRef)

  useEffect(() => {
    if (isInView) {
      animation?.play()
    }
  }, [animation, isInView])

  const afterContent = (
    <SignupButtonText size="medium" tag="a" appearance={appearance}>
      Create a free account
      <ChevronRightIcon />
    </SignupButtonText>
  )

  return (
    <AppearanceProvider appearance={appearance}>
      <Wrapper>
        <StyledHero variant="2Col">
          <HeroRecipe.Copy>
            <CopyText.Heading variant="homeH2" tag="h1">
              {copy.heading}
            </CopyText.Heading>
            <CopyText.Description variant="homePXXL" tag="p">
              {copy.description}
            </CopyText.Description>

            <HeroRecipe.Form>
              <DemoFormContainer data-hook="demoFormContainer">
                <DemoForm isProgressive={true} after={afterContent} />
              </DemoFormContainer>
            </HeroRecipe.Form>
          </HeroRecipe.Copy>
          <HeroRecipe.Contents>
            {animationData ? (
              <Animation ref={containerRef} />
            ) : (
              <picture>
                <source type="image/webp" srcSet={`${heroWebp}, ${heroWebp2x} 2x`} />
                <HeroRecipe.Image
                  width="641"
                  height="541"
                  src={heroPng2x}
                  alt="Illustration: Customer Data Platform diagram"
                  loading="eager"
                />
              </picture>
            )}
          </HeroRecipe.Contents>
        </StyledHero>
      </Wrapper>
    </AppearanceProvider>
  )
}

const Wrapper = styled.header`
  background: ${({ theme }) => theme.palette.twilio.gray[100]};
`

const StyledHero = styled(HeroRecipeBase)`
  ${HeroRecipe.Wrapper} {
    gap: 50px;
  }
`

const DemoFormContainer = styled.div`
  margin-top: 50px;

  ${({ theme }) => theme.breakpoints.down(`md`)} {
    margin-top: 0;
  }
`

const Animation = styled.div`
  width: 100%;
`

export default Hero
