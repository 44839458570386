import styled from '@emotion/styled'

import { useTheme } from '@emotion/react'

import ColumnTrack from '@segment/matcha/components/ColumnTrack'
import ButtonText from '@segment/matcha/components/ButtonText'

import { ChevronRightIcon } from '@segment/matcha/components/Icons/Common'
import { DataIcon, PeopleIcon, ProductIcon } from '@segment/matcha/components/Icons/Twilio'

import IconWrapper from '@segment/matcha/components/IconWrapper'

import FeatureBoxBase, * as FeatureBox from '@segment/matcha/components/FeatureBox'

import SectionBase, * as Section from '@segment/matcha/recipes/Section'
import CopyTextBase, * as CopyText from '@segment/matcha/recipes/CopyText'

import getAbsoluteUrl from '@segment/utils/getAbsoluteUrl'

import { usePageConfig } from '@segment/contexts/page'

const copy = {
  heading: `A CDP that works hard for every team, backed by enterprise-grade infrastructure`,
}

const features = [
  {
    icon: <DataIcon size="medium" />,
    title: `Data engineers`,
    text: `Segment’s libraries, automatic sources, and functions collect data from anywhere—hundreds of thousands of times per second—so you can move it where you need it.`,
    anchorText: `Segment for engineers`,
    href: `/engineering/`,
  },
  {
    icon: <PeopleIcon size="medium" />,
    title: `Marketers`,
    text: `Know your customers through rich profiles and use AI to anticipate their needs and create personalized campaigns that improve ROI.`,
    anchorText: `Segment for marketers`,
    href: `/marketing/`,
  },
  {
    icon: <ProductIcon size="medium" />,
    title: `Product managers`,
    text: `Get a complete view of your customers for better personalization, conversion optimization, and predictive recommendations.`,
    anchorText: `Segment for product`,
    href: `/product/`,
  },
]

const DataArchitecture = () => {
  const pageConfig = usePageConfig()
  const theme = useTheme()

  return (
    <StyledSectionBase variant="centered">
      <Section.Copy>
        <CopyTextBase centered={true}>
          <CopyText.Heading tag="h2" variant="homeH3">
            {copy.heading}
          </CopyText.Heading>
        </CopyTextBase>
      </Section.Copy>
      <Section.Contents>
        <ColumnTrack columns={1} overrides={{ lg: 3 }}>
          {features.map((feature) => (
            <FeatureBoxBase key={feature.title} alignment="left" headerPosition="top">
              <FeatureBox.Header>
                <IconWrapper
                  variant="circle"
                  elevation={1}
                  tokens={{ backgroundColor: theme.palette.twilio.mint[100] }}
                >
                  {feature.icon}
                </IconWrapper>
              </FeatureBox.Header>

              <FeatureBox.Body>
                <FeatureBox.Title variant="homeH4">{feature.title}</FeatureBox.Title>
                <FeatureBox.Text variant="pMedium">{feature.text}</FeatureBox.Text>
                <StyledButtonText
                  size="medium"
                  href={getAbsoluteUrl(feature.href, pageConfig)}
                  iconEnd={ChevronRightIcon}
                >
                  {feature.anchorText}
                </StyledButtonText>
              </FeatureBox.Body>
            </FeatureBoxBase>
          ))}
        </ColumnTrack>
      </Section.Contents>
    </StyledSectionBase>
  )
}

const StyledSectionBase = styled(SectionBase)`
  ${Section.Wrapper} {
    padding-bottom: 0;
    row-gap: 45px;
  }
`

const StyledButtonText = styled(ButtonText)`
  justify-self: start;
`

export default DataArchitecture
