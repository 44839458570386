import type { FC, PropsWithChildren } from 'react'
import styled from '@emotion/styled'

import { AppearanceProvider } from '@segment/matcha/foundations/AppearanceContext'

import SectionBase, * as Section from '@segment/matcha/recipes/Section'
import CopyTextBase, * as CopyText from '@segment/matcha/recipes/CopyText'

import TestimonialSlider from '@segment/templates/TestimonialSlider'

import { testimonialData } from './data'

const copy = {
  heading: `The world’s leading brands are powered by Twilio Segment`,
  description: `From startups to global enterprises, see what businesses have achieved with the Twilio Segment customer data platform.`,
}

const TestimonialsSlider: FC<PropsWithChildren<unknown>> = () => {
  return (
    <StyledSection variant="centered">
      <Section.Copy>
        <CopyTextBase centered={true}>
          <CopyText.Heading variant="homeH2" tag="h2">
            {copy.heading}
          </CopyText.Heading>
          <CopyText.Description variant="homePExtraLarge" tag="p">
            {copy.description}
          </CopyText.Description>
        </CopyTextBase>
      </Section.Copy>
      <AppearanceProvider appearance="twilioDark">
        <TestimonialSlider data={testimonialData} innerStyles={false} title={false} />
      </AppearanceProvider>
    </StyledSection>
  )
}

const StyledSection = styled(SectionBase)`
  max-width: 1260px;
  overflow-x: visible;
`

export default TestimonialsSlider
