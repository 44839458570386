import React from 'react'
import styled from '@emotion/styled'

import { useTheme } from '@emotion/react'

import { AppearanceProvider } from '@segment/matcha/foundations/AppearanceContext'

import Button from '@segment/matcha/components/Button'

import CopyTextBase, * as CopyText from '@segment/matcha/recipes/CopyText'

import LogoTiles from '@segment/templates/LogoTiles'

import getAbsoluteUrl from '@segment/utils/getAbsoluteUrl'

import { usePageConfig } from '@segment/contexts/page'

const copy = {
  heading: `Integrations`,
  description: `450+ pre-built integrations that you can enable with the flip of a switch.`,
  button: {
    text: `Explore integrations`,
    href: `/catalog/`,
  },
}

const Integrations = () => {
  const pageConfig = usePageConfig()
  const theme = useTheme()

  return (
    <Wrapper>
      <AppearanceProvider appearance="twilioDark">
        <Copy>
          <CopyTextBase centered={true}>
            <CopyText.Heading tag="h2" variant="homeH2">
              {copy.heading}
            </CopyText.Heading>
            <CopyText.Description tag="p" variant="homePExtraLarge">
              {copy.description}
            </CopyText.Description>
          </CopyTextBase>
          <ButtonWrapper>
            <Button
              size="large"
              color="primary"
              variant="filled"
              tag="a"
              href={getAbsoluteUrl(`${copy.button.href}`, pageConfig)}
            >
              {copy.button.text}
            </Button>
          </ButtonWrapper>
        </Copy>
      </AppearanceProvider>

      <LogoTiles tokens={{ gradientColor: theme.palette.twilio.gray[100] }} />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  padding-top: 80px;
  background: ${({ theme }) => theme.palette.twilio.gray[100]};
`

const Copy = styled.div`
  display: grid;
  justify-content: center;
  row-gap: 40px;
  padding: 0 20px;
`

const ButtonWrapper = styled.div`
  justify-self: center;
  margin-bottom: 30px;

  ${({ theme }) => theme.breakpoints.down(`md`)} {
    margin-bottom: 0;
  }
`

export default Integrations
