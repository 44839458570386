import type { FC, PropsWithChildren } from 'react'
import styled from '@emotion/styled'
import { transparentize } from 'polished'

import { useTheme } from '@emotion/react'

import sanitizeHtml from 'sanitize-html'

import type { ButtonProps } from '@segment/matcha/components/Button'
import Button from '@segment/matcha/components/Button'
import Typography from '@segment/matcha/components/Typography'
import Divider from '@segment/matcha/components/Divider'

import * as CopyText from '@segment/matcha/recipes/CopyText'

import SectionBase, * as Section from '@segment/matcha/recipes/Section'

import getAbsoluteUrl from '@segment/utils/getAbsoluteUrl'

import { usePageConfig } from '@segment/contexts/page'

const copy = {
  heading: `See Twilio Segment in action`,
  description: `Learn how you can start connecting your data with Twilio Segment. <a href="/pricing">Explore our plans</a> to find the one that’s right for your team.`,
  caption: `*  Twilio is the #1 CDP for market share four years in a row per IDC (2019-2022).`,
  caption2: `** Statistics from 6/30/22 - 6/30/23`,
}

type Button = {
  text: string
  href: string
  variant: ButtonProps[`variant`]
}

const buttons: Button[] = [
  {
    text: `View interactive demo`,
    href: `/product-tour?utm_source=site&utm_medium=referral&utm_campaign=homepage/`,
    variant: `filled`,
  },
  {
    text: `Pricing`,
    href: `/pricing/`,
    variant: `outlined`,
  },
]

const GettingStarted: FC<PropsWithChildren<{}>> = () => {
  const theme = useTheme()
  const pageConfig = usePageConfig()

  return (
    <Wrapper>
      <StyledSection variant="centered">
        <Section.Copy>
          <CopyText.Heading variant="homeH2" tag="h2">
            {copy.heading}
          </CopyText.Heading>
          <StyledDescription
            variant="homePExtraLarge"
            tag="p"
            dangerouslySetInnerHTML={{
              __html: sanitizeHtml(copy.description, { allowedTags: [`a`] }),
            }}
          ></StyledDescription>
        </Section.Copy>

        <Section.Contents>
          <ButtonsWrapper>
            {buttons.map((button) => (
              <Button
                key={button.text}
                size="large"
                color="primary"
                variant={button.variant}
                href={getAbsoluteUrl(button.href, pageConfig)}
              >
                {button.text}
              </Button>
            ))}
          </ButtonsWrapper>
        </Section.Contents>
      </StyledSection>

      <DividerSection>
        <Divider tokens={{ color: theme.palette.twilio.gray[40] }} />
      </DividerSection>

      <Caption>
        <StyledTypography variant="pSmall">{copy.caption}</StyledTypography>
        <StyledTypography variant="pSmall">{copy.caption2}</StyledTypography>
      </Caption>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background: ${({ theme }) => transparentize(0.85, theme.palette.twilio.mint[100])};
`

const StyledSection = styled(SectionBase)`
  ${Section.Wrapper} {
    ${({ theme }) => theme.breakpoints.down(`md`)} {
      gap: 40px;
    }
  }
`

const ButtonsWrapper = styled.div`
  display: flex;
  gap: 24px;
  justify-content: center;

  ${({ theme }) => theme.breakpoints.down(`sm`)} {
    flex-direction: column;
  }
`

const DividerSection = styled.div`
  max-width: 1248px;
  margin: 0 auto;
  padding: 0 24px;
`

const Caption = styled.div`
  max-width: 1040px;
  margin: 0 auto;
  padding: 40px 24px 80px 24px;
`

const StyledTypography = styled(Typography)`
  margin: 0;
`

const StyledDescription = styled(CopyText.Description)`
  a {
    color: ${({ theme }) => theme.palette.twilio.gray[100]};
  }
`

export default GettingStarted
