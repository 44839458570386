/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var DataIcon = createIconsComponent(_jsxs("svg", {
  viewBox: "0 0 29 26",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M16.341 6.375a.9.9 0 0 1 .9-.9h8.901a.9.9 0 0 1 0 1.8h-8.9a.9.9 0 0 1-.9-.9Z",
    fill: "none"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M2 2v21.017h10.22a2.987 2.987 0 0 1-.438-1.563V3.564c0-.573.16-1.109.439-1.564H2Zm13.651-2a1 1 0 0 1 1 1v.563a1 1 0 0 1-1 1h-.87a1 1 0 0 0-1 1v17.89a1 1 0 0 0 1 1h.87a1 1 0 0 1 1 1v.564a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V1a1 1 0 0 1 1-1h14.651Z",
    fill: "none"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M4.228 6.375c0-.553.39-1 .87-1h3.676c.48 0 .87.447.87 1 0 .552-.39 1-.87 1H5.098c-.48 0-.87-.448-.87-1ZM4.228 10.526c0-.552.39-1 .87-1h3.676c.48 0 .87.448.87 1s-.39 1-.87 1H5.098c-.48 0-.87-.448-.87-1ZM4.228 14.491c0-.552.39-1 .87-1h3.676c.48 0 .87.448.87 1s-.39 1-.87 1H5.098c-.48 0-.87-.448-.87-1ZM4.228 18.456c0-.552.39-1 .87-1h3.676c.48 0 .87.448.87 1s-.39 1-.87 1H5.098c-.48 0-.87-.448-.87-1Z",
    fill: "none"
  }), _jsx("path", {
    d: "M28.124 6.375a1.982 1.982 0 1 1-3.964 0 1.982 1.982 0 0 1 3.964 0Z",
    fill: "none"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M16.341 14.491a.9.9 0 0 1 .9-.9h8.901a.9.9 0 1 1 0 1.8h-8.9a.9.9 0 0 1-.9-.9Z",
    fill: "none"
  }), _jsx("path", {
    d: "M28.125 14.491a1.982 1.982 0 1 1-3.965 0 1.982 1.982 0 0 1 3.965 0Z",
    fill: "none"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M16.342 10.526a.9.9 0 0 1 .9-.9h6.028a.9.9 0 0 1 0 1.8h-6.028a.9.9 0 0 1-.9-.9Z",
    fill: "none"
  }), _jsx("path", {
    d: "M25.252 10.526a1.982 1.982 0 1 1-3.964 0 1.982 1.982 0 0 1 3.964 0Z",
    fill: "none"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M16.342 18.456a.9.9 0 0 1 .9-.9h6.028a.9.9 0 0 1 0 1.8h-6.028a.9.9 0 0 1-.9-.9Z",
    fill: "none"
  }), _jsx("path", {
    d: "M25.252 18.456a1.982 1.982 0 1 1-3.964 0 1.982 1.982 0 0 1 3.964 0Z",
    fill: "none"
  })]
}), 'DataIcon', 'Twilio');
export default DataIcon;