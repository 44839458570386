/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var PeopleIcon = createIconsComponent(_jsxs("svg", {
  viewBox: "0 0 28 27",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M5.194 5.353A5.598 5.598 0 0 1 9.078 3.8c1.45 0 2.848.555 3.885 1.554A5.273 5.273 0 0 1 14.59 9.15c0 1.432-.59 2.796-1.627 3.797A5.598 5.598 0 0 1 9.078 14.5a5.598 5.598 0 0 1-3.884-1.554 5.273 5.273 0 0 1-1.628-3.797c0-1.43.59-2.795 1.628-3.796Zm3.884.446c-.943 0-1.84.361-2.496.994a3.273 3.273 0 0 0-1.016 2.356c0 .877.361 1.726 1.016 2.357a3.598 3.598 0 0 0 2.496.994c.943 0 1.84-.361 2.496-.994a3.274 3.274 0 0 0 1.016-2.357c0-.876-.361-1.725-1.016-2.356a3.598 3.598 0 0 0-2.496-.994Z",
    fill: "none"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M9.078 16.082a6.524 6.524 0 0 0-4.526 1.805 5.993 5.993 0 0 0-1.857 4.314 1 1 0 1 1-2 0c0-2.165.893-4.234 2.469-5.754a8.524 8.524 0 0 1 5.914-2.365c2.212 0 4.34.847 5.915 2.365a7.993 7.993 0 0 1 2.468 5.754 1 1 0 1 1-2 0c0-1.61-.663-3.163-1.857-4.314a6.524 6.524 0 0 0-4.526-1.805ZM20.61 6.212a3.648 3.648 0 0 0-3.617.137 1 1 0 0 1-1.062-1.695 5.648 5.648 0 0 1 5.607-.213c.87.456 1.601 1.132 2.112 1.962.512.83.783 1.78.782 2.751 0 .97-.274 1.921-.787 2.75a5.444 5.444 0 0 1-2.115 1.958 5.649 5.649 0 0 1-5.607-.222 1 1 0 1 1 1.065-1.693 3.649 3.649 0 0 0 3.617.142 3.444 3.444 0 0 0 1.34-1.237c.32-.517.486-1.104.487-1.7 0-.596-.166-1.183-.484-1.7a3.443 3.443 0 0 0-1.338-1.24Z",
    fill: "none"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M20.35 16.239a6.658 6.658 0 0 0-2.855-.004 1 1 0 1 1-.427-1.954 8.658 8.658 0 0 1 3.713.005 8.509 8.509 0 0 1 3.353 1.56 8.18 8.18 0 0 1 2.332 2.82c.55 1.101.837 2.31.839 3.534a1 1 0 1 1-2 .002 5.916 5.916 0 0 0-.628-2.64 6.176 6.176 0 0 0-1.763-2.131 6.508 6.508 0 0 0-2.564-1.192Z",
    fill: "none"
  })]
}), 'PeopleIcon', 'Twilio');
export default PeopleIcon;