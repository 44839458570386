import type { FC, PropsWithChildren } from 'react'
import styled from '@emotion/styled'

import Button from '@segment/matcha/components/Button'
import Image from '@segment/matcha/components/Image'

import * as CopyText from '@segment/matcha/recipes/CopyText'
import SectionBase, * as Section from '@segment/matcha/recipes/Section'

import getAbsoluteUrl from '@segment/utils/getAbsoluteUrl'

import { usePageConfig } from '@segment/contexts/page'

import TheGrowthReportPng2x from './assets/TheGrowthReport@2x.png'
import TheGrowthReportWebp from './assets/TheGrowthReport.webp'
import TheGrowthReportWebp2x from './assets/TheGrowthReport@2x.webp'

const copy = {
  heading: `The Growth Report 2023: AI Edition`,
  description: `Get insights from 2,450 marketing and CX leaders on what it means to grow sustainably in 2023, and how companies are using AI to do just that.`,
  button: {
    text: `Read it here`,
    href: `/the-growth-report`,
  },
}

const TheGrowthReport: FC<PropsWithChildren<{}>> = () => {
  const pageConfig = usePageConfig()

  return (
    <Wrapper>
      <StyledSection variant="2Col">
        <Section.Copy alignSelf="center">
          <CopyText.Heading variant="homeH3" tag="h2">
            {copy.heading}
          </CopyText.Heading>
          <CopyText.Description variant="homePExtraLarge" tag="p">
            {copy.description}
          </CopyText.Description>

          <ButtonWrapper>
            <Button size="large" color="primary" variant="filled" href={getAbsoluteUrl(copy.button.href, pageConfig)}>
              {copy.button.text}
            </Button>
          </ButtonWrapper>
        </Section.Copy>

        <Section.Contents>
          <picture>
            <source type="image/webp" srcSet={`${TheGrowthReportWebp}, ${TheGrowthReportWebp2x} 2x`} />
            <StyledImage src={TheGrowthReportPng2x} alt="The Growth Report" width="744" height="589" loading="lazy" />
          </picture>
        </Section.Contents>
      </StyledSection>
    </Wrapper>
  )
}

const Wrapper = styled.div``

const StyledSection = styled(SectionBase)`
  ${Section.Wrapper} {
    gap: 80px;

    ${({ theme }) => theme.breakpoints.down(`md`)} {
      gap: 40px;
    }
  }
`

const ButtonWrapper = styled.div`
  margin-top: 20px;
`

const StyledImage = styled(Image)`
  width: 100%;
  max-width: 500px;
  filter: drop-shadow(${({ theme }) => theme.shadows[2]});
`

export default TheGrowthReport
