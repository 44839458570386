import React from 'react'
import styled from '@emotion/styled'

import { useTheme } from '@emotion/react'

import Button from '@segment/matcha/components/Button'
import Image from '@segment/matcha/components/Image'

import SectionBase, * as Section from '@segment/matcha/recipes/Section'
import CopyTextBase, * as CopyText from '@segment/matcha/recipes/CopyText'
import TabsLayoutBase, * as TabsLayout from '@segment/matcha/recipes/TabsLayout'
import useTabs from '@segment/matcha/recipes/Tabs/useTabs'

import getAbsoluteUrl from '@segment/utils/getAbsoluteUrl'

import { usePageConfig } from '@segment/contexts/page'

import AIReadyDataPng2x from './assets/AIReadyData@2x.png'
import AIReadyDataWebp from './assets/AIReadyData.webp'
import AIReadyDataWebp2x from './assets/AIReadyData@2x.webp'
import GenerativeAIPng2x from './assets/GenerativeAI@2x.png'
import GenerativeAIWebp from './assets/GenerativeAI.webp'
import GenerativeAIWebp2x from './assets/GenerativeAI@2x.webp'
import PredictiveAIPng2x from './assets/PredictiveAI@2x.png'
import PredictiveAIWebp from './assets/PredictiveAI.webp'
import PredictiveAIWebp2x from './assets/PredictiveAI@2x.webp'
import SecurityAndPrivacyPng2x from './assets/SecurityAndPrivacy@2x.png'
import SecurityAndPrivacyWebp from './assets/SecurityAndPrivacy.webp'
import SecurityAndPrivacyWebp2x from './assets/SecurityAndPrivacy@2x.webp'

const copy = {
  heading: `Provide 1:1 interactions at scale with CustomerAI`,
}

const tabs = [
  {
    key: `generative-ai`,
    heading: `Generative AI`,
    description: `Create targeted audiences and launch personalized customer journeys within minutes using simple text prompts.`,
    link: { text: `Explore CustomerAI`, url: `/solutions/ai` },
    image: {
      png2x: GenerativeAIPng2x,
      webp: GenerativeAIWebp,
      webp2x: GenerativeAIWebp2x,
      width: 720,
      height: 720,
    },
  },
  {
    key: `predictive-ai`,
    heading: `Predictive AI`,
    description: `Predict customer behavior to build targeted audiences and more personalized campaigns that lower costs and increase conversions.`,
    link: {
      text: `Explore CustomerAI Predictions`,
      url: `/solutions/ai/customerai-predictions/`,
    },
    image: {
      dropShadow: true,
      png2x: PredictiveAIPng2x,
      webp: PredictiveAIWebp,
      webp2x: PredictiveAIWebp2x,
      width: 721,
      height: 721,
    },
  },
  {
    key: `ai-ready-data`,
    heading: `AI-ready data`,
    description: `Your AI outputs are only as good as the data you put in. Start with clean data, centralized privacy, and governance to get accurate recommendations and predictions.`,
    link: { text: `Explore CustomerAI`, url: `/solutions/ai/` },
    image: {
      dropShadow: true,
      png2x: AIReadyDataPng2x,
      webp: AIReadyDataWebp,
      webp2x: AIReadyDataWebp2x,
      width: 721,
      height: 721,
    },
  },
  {
    key: `security-and-privacy`,
    heading: `Security and privacy`,
    description: `We promote the ethical and responsible use of data privacy with AI. With all Segment products, you have full visibility into and control over the personal information you collect from customers so you can protect it and meet privacy requirements.`,
    link: { text: `See our privacy portal`, url: `/product/privacy-portal/` },
    image: {
      dropShadow: true,
      png2x: SecurityAndPrivacyPng2x,
      webp: SecurityAndPrivacyWebp,
      webp2x: SecurityAndPrivacyWebp2x,
      width: 721,
      height: 721,
    },
  },
]

const Interactions = () => {
  const pageConfig = usePageConfig()
  const theme = useTheme()
  const { getTrackProps, getControlProps, getPanelProps, activeTabKey } = useTabs(0, tabs.length, `interactions`)

  return (
    <Wrapper>
      <StyledSection variant="centered">
        <Section.Copy>
          <CopyTextBase centered={true}>
            <CopyText.Heading tag="h2" variant="homeH2">
              {copy.heading}
            </CopyText.Heading>
          </CopyTextBase>
        </Section.Copy>

        <Section.Contents>
          <TabsLayoutBase>
            <TabsLayout.Controls tokens={{ backgroundColor: theme.palette.twilio.mint[20] }} {...getTrackProps()}>
              {tabs.map((tab, index) => (
                <TabsLayout.Control
                  tokens={{
                    root: {
                      color: theme.palette.twilio.gray[100],
                      hoverColor: theme.palette.twilio.gray[100],
                      outlineColor: theme.palette.twilio.gray[100],
                    },
                  }}
                  key={tab.key}
                  {...getControlProps(index)}
                >
                  {tab.heading}
                </TabsLayout.Control>
              ))}
            </TabsLayout.Controls>

            {tabs.map((tab, index) => (
              <TabsLayout.Content {...getPanelProps(index)} active={index === activeTabKey} key={tab.heading}>
                <TabsLayout.Copy>
                  <CopyTextBase>
                    <CopyText.Heading variant="homeH4">{tab.heading}</CopyText.Heading>
                    <CopyText.Description variant="pMedium">{tab.description}</CopyText.Description>

                    <ButtonWrapper>
                      <Button
                        size="large"
                        variant="filled"
                        color="primary"
                        href={getAbsoluteUrl(tab.link.url, pageConfig)}
                      >
                        {tab.link.text}
                      </Button>
                    </ButtonWrapper>
                  </CopyTextBase>
                </TabsLayout.Copy>
                <TabsLayout.Media>
                  <picture>
                    <source type="image/webp" srcSet={`${tab.image.webp}, ${tab.image.webp2x} 2x`} />
                    <StyledImage
                      alt={tab.heading}
                      width={tab.image.width}
                      height={tab.image.height}
                      src={tab.image.png2x}
                      loading="lazy"
                    />
                  </picture>
                </TabsLayout.Media>
              </TabsLayout.Content>
            ))}
          </TabsLayoutBase>
        </Section.Contents>
      </StyledSection>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background: ${({ theme }) => theme.palette.twilio.mint[10]};
`

const StyledSection = styled(SectionBase)`
  ${Section.Wrapper} {
    ${({ theme }) => theme.breakpoints.down(`md`)} {
      gap: 40px;
    }
  }
`

const StyledImage = styled(Image)`
  width: 100%;
  max-width: 490px;
  filter: drop-shadow(${({ theme }) => theme.shadows[2]});
`

const ButtonWrapper = styled.div`
  margin-top: 40px;
`

export default Interactions
